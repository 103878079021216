import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loading from "./Page/Loading";
import Login from "./Page/Login";
import { AuthProvider } from "./Utils/auth-context";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path={[
              "/",
              "/product",
              "/product/add",
              "/product/change",
              "/product/update",
            ]}
            component={Loading}
          />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
