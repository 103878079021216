import React, { useState } from "react";
import { BsGraphUp, BsPower, BsBucketFill } from "react-icons/bs";
import { useHistory, useLocation } from "react-router";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import { API_Logout } from "../Utils/api";

const Menu = () => {
  const history = useHistory();

  const path = useLocation<{}>();

  const [Data, setData] = useState([
    { path: "/", name: "แดชบอร์ด", icon: <BsGraphUp /> },
    { path: "/product", name: "สินค้า", icon: <BsBucketFill /> },
  ]);

  const logout = () => {
    Swal.fire({
      icon: "warning",
      title: "คำเตือน",
      text: "คุณต้องการที่จะออกจากระบบ ใช่ หรือ ไม่?",
      timer: 5000,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
      timerProgressBar: true,
    }).then((res) => {
      if (res.value) {
        API_Logout().finally(() => {
          localStorage.removeItem("token");
          Swal.fire({
            icon: "success",
            title: "สำเร็จ",
            text: "ออกจากระบบเสร็จสิ้น",
            timer: 5000,
            timerProgressBar: true,
          }).then(() => {
            window.location.href = "/login";
          });
        });
      }
    });
  };

  return (
    <div className="sidebar">
      <h5 className="title is-5 has-text-centered">Product Connect</h5>
      <h4 className="subtitle is-4 has-text-centered">Manager</h4>
      <hr />
      <aside className="menu">
        {Data.map((item, index) => (
          <ul key={index} className="menu-list">
            <li
              data-for="menu"
              data-tip={item.name}
              onClick={() => history.push(item.path)}
            >
              <a className={path.pathname === item.path ? "is-active" : ""}>
                {item.icon} <b>{item.name}</b>
              </a>
            </li>
          </ul>
        ))}
        <ul className="menu-list">
          <li data-tip="ออกจากระบบ" data-for="menu" onClick={() => logout()}>
            <a>
              <BsPower /> <b>ออกจากระบบ</b>
            </a>
          </li>
        </ul>
      </aside>
      <ReactTooltip id="menu" place="left" effect="solid" />
    </div>
  );
};

export default Menu;
