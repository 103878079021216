/* eslint-disable array-callback-return */
import React, { useCallback, useContext, useState } from "react";
import {
  BsBucketFill,
  BsUpload,
  BsCloudUpload,
  BsXCircleFill,
  BsCheckCircle,
  BsExclamationTriangleFill,
  BsArrowRepeat,
  BsPlusSquareFill,
} from "react-icons/bs";
import { useHistory } from "react-router";
import Menu from "../../Components/Menu";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import { AuthContext } from "../../Utils/auth-context";
import { API_Product_Change_SKP, API_Product_Change_PNG, API_Product_Change_GLTF, API_Product_Change_PDF } from "../../Utils/api";
import axios from "axios";

const Product_Change_Page = () => {
  interface ItemStruct {
    ProductConnectID: string;
    png?: File;
    skp?: File;
    gltf?: File;
    pdf?: File;
    material?: File;
  }

  const history = useHistory();

  const context = useContext(AuthContext);

  const [Status, setStatus] = useState<Array<Number>>([]);
  const [Data, setData] = useState<Array<ItemStruct>>([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      DropFile(acceptedFiles);
    },
    [Data]
  );

  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  const CheckFile = (file: File, path: Array<string>) => {
    if (file.type.match("image.*")) return "png";
    else if (path[path.length - 1] === "skp") {
      if (path[0].match("material")) {
        return "material";
      } else {
        return "skp";
      }
    } else if (
      path[path.length - 1] === "gltf" ||
      path[path.length - 1] === "glb"
    )
      return "gltf";
    else if (file.type.match("zip")) return "pdf";
    else if (file.type.match("pdf")) return "pdf";
    else return "";
  };

  const DropFile = (acceptedFiles: Array<File>) => {
    let datanew = [] as Array<ItemStruct>;
    acceptedFiles.forEach((file: File) => {
      const path = file.name.split(".");
      const name = path[0].split("_");
      const typename = CheckFile(file, path);
      if (typename !== "") {
        let data = Data.find(
          (item: ItemStruct) => item.ProductConnectID === name[0]
        );
        if (data !== undefined) {
          data[typename] = file;
          setData((preState) => [...preState]);
        } else {
          const checkdatanew = datanew.find(
            (item: ItemStruct) => item.ProductConnectID === name[0]
          );
          const dataset = {
            ProductConnectID: name[0],
            [typename]: file,
          } as ItemStruct;
          if (checkdatanew !== undefined) {
            checkdatanew[typename] = file;
          } else {
            datanew.push(dataset);
          }
        }
      }
    });

    if (datanew.length > 0) {
      setData((prevState) => [...prevState, ...datanew]);
    }
  };

  const [Btn, setBtn] = useState([
    { path: "/product", name: "หน้าสินค้าทั้งหมด", icon: <BsBucketFill /> },
    { path: "/product/add", name: "หน้าเพิ่มสินค้า", icon: <BsPlusSquareFill /> },
    { path: "/product/update", name: "หน้าอัพเดทข้อมูล", icon: <BsUpload /> },
  ]);

  const GetStatus = (index: number) => {
    switch (Status[index]) {
      case 0:
        return (
          <BsArrowRepeat
            style={{ animation: "loading_upload 1s linear infinite" }}
            className="has-text-info"
          />
        );
      case 1:
        return <BsCheckCircle className="has-text-success" />;
      case 2:
        return <BsExclamationTriangleFill className="has-text-warning" />;
      case 3:
        return <BsXCircleFill className="has-text-danger" />;
    }
  };

  const Upload = () => {
    if (Data.length > 0) {
      Data.map((item : ItemStruct) => {
        let api = [];
        if (item.gltf !== undefined) {
          api.push(API_Product_Change_GLTF(context.id_brand[0], item.ProductConnectID, item.gltf));
        }

        if (item.pdf !== undefined) {
          api.push(API_Product_Change_PDF(context.id_brand[0], item.ProductConnectID, item.pdf));
        }

        if (item.png !== undefined) {
          api.push(API_Product_Change_PNG(context.id_brand[0], item.ProductConnectID, item.png));
        }

        if (item.skp !== undefined) {
          api.push(API_Product_Change_SKP(context.id_brand[0], item.ProductConnectID, item.skp));
        }

        axios.all(api).then(res => {
          console.log(res);
          res.map(item => {
            if (item.status >= 200) {
              Swal.fire({
                icon: "success",
                title: "สำเร็จ",
                text: item.data.message,
                timer: 5000,
                timerProgressBar: true,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: item.data.error,
                timer: 5000,
                timerProgressBar: true,
              });
            }
          })
        }).catch(err => {
          if (err.status >= 400 || err.status < 500) {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: err.data.error,
              timer: 5000,
              timerProgressBar: true,
            });
          } else {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
        timer: 5000,
        timerProgressBar: true,
      });

          }

        });
      }
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "ไม่พบข้อมูลสินค้าในการอัพเดทข้อมูล",
        timer: 5000,
        timerProgressBar: true,
      });
    }
  };
  return (
    <div
      className="is-flex"
      style={{ position: "relative" }}
      {...getRootProps()}
    >
      {isDragActive && (
        <>
          <h1 className="overlay-text">
            <BsCloudUpload />
            <b>อัพโหลดไฟส์</b>
          </h1>
          <div className="overlay"></div>
        </>
      )}
      <Menu />
      <div className="container m-5">
        <h5 className="subtitle is-5">หน้าเปลื่ยนไฟส์ต่างๆ</h5>
        <div className="card column mb-2">
          <label className="is-block">หน้าที่เกี่ยวข้อง</label>
          {Btn.map((item, index) => (
            <button
              key={index}
              onClick={() => history.push(item.path)}
              className="button is-link mt-1 mr-2"
            >
              <b className="mr-2">{item.icon}</b>
              {item.name}
            </button>
          ))}
        </div>
        <div className="card column">
          <div className="card-content">
            <div className="is-flex is-justify-content-space-between is-align-items-center mb-3">
              <div>
                {/* <SelectKind set={SetKind} /> */}
                <button onClick={() => Upload()} className="button is-link">
                  อัพเดทไฟส์ทั้งหมด
                </button>
              </div>
              <p>รายการทั้งหมด {Data.length} ตัว</p>
            </div>
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th></th>
                  <th>ลำดับ</th>
                  <th>รหัสสินค้า</th>
                  <th>รูปภาพ</th>
                  <th>ไฟส์ SKP</th>
                  <th>โมเดล ใช้แสดง</th>
                  <th>PDF</th>
                </tr>
              </thead>
              <tbody>
                {Data.map((item, index) => (
                  <tr key={index}>
                    <th>{GetStatus(index)}</th>
                    <th>{index + 1}</th>
                    <th>{item.ProductConnectID}</th>
                    <th
                      className={` ${
                        item.png ? "has-text-success" : "has-text-danger"
                      } `}
                    >
                      {item.png ? <BsCheckCircle /> : <BsXCircleFill />}
                    </th>
                    <th
                      className={` ${
                        item.skp ? "has-text-success" : "has-text-danger"
                      } `}
                    >
                      {item.skp ? <BsCheckCircle /> : <BsXCircleFill />}
                    </th>
                    <th
                      className={` ${
                        item.gltf ? "has-text-success" : "has-text-danger"
                      } `}
                    >
                      {item.gltf ? <BsCheckCircle /> : <BsXCircleFill />}
                    </th>
                    <th
                      className={` ${
                        item.pdf ? "has-text-success" : "has-text-danger"
                      } `}
                    >
                      {item.pdf ? <BsCheckCircle /> : <BsXCircleFill />}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product_Change_Page;
