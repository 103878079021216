import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import Home from "../Page/Home";
import Product_Page from "../Page/Proudct";
import Product_Add_Page from "../Page/Proudct/Add";
import Product_Update_Page from "../Page/Proudct/Update";
import { AuthContext } from "../Utils/auth-context";
import "../loading.css";
import { useHistory } from "react-router";
import { API_Me } from "../Utils/api";
import Product_Change_Page from "./Proudct/Change";

const Loading = () => {
  const context = useContext(AuthContext);

  const history = useHistory();

  const Clear = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    history.push("/login");
  };

  useEffect(() => {
    if (localStorage.hasOwnProperty("token")) {
      API_Me()
        .then(async (res) => {
          if (res.status === 200) {
            if (res.data.status) {
              await context.id_brand[1](res.data.data.id_brand);
              await context.auth[1](true);
            }
          } else {
            if (localStorage.hasOwnProperty("refresh_token")) {
              window.location.reload();
            } else {
              Clear();
            }
          }
        })
        .catch(() => {
          Clear();
        });
    } else {
      history.push("/login");
    }
  }, []);

  if (!context.auth[0]) {
    return (
      <div
        className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="loadingio-spinner-bean-eater-hyyyx7u0h3">
          <div className="ldio-wjptittm8z">
            <div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <h3 className="title is-3">กำลังโหลด...</h3>
      </div>
    );
  }

  return (
    <>
      <Route exact path="/" component={Home} />
      <Route exact path="/load" component={Loading} />
      <Route exact path="/product" component={Product_Page} />
      <Route exact path="/product/add" component={Product_Add_Page} />
      <Route exact path="/product/change" component={Product_Change_Page} />
      <Route exact path="/product/update" component={Product_Update_Page} />
    </>
  );
};

export default Loading;
