import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../Utils/auth-context";
import { BsFolderFill, BsPlusSquareFill, BsTrashFill, BsUpload } from "react-icons/bs";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Menu from "../../Components/Menu";
import { API_Product, API_Product_Remove, API_Product_Remove_All } from "../../Utils/api";
import DataTable from "react-data-table-component";

interface ItemStruct {
  id_product: string;
  productconnect_id: string;
  name_product: string;
  price_product: number;
}
const Product_Page = () => {
  const history = useHistory();

  const context = useContext(AuthContext);

  const [Data, setData] = useState<Array<ItemStruct>>([]);
  const [Selected, setSelected] = useState<Array<string>>([]);

  const [Btn, setBtn] = useState([
    {
      path: "/product/add",
      name: "หน้าเพิ่มสินค้า",
      icon: <BsPlusSquareFill />,
    },
    {
       path: "/product/update",
       name: "หน้าอัพเดทข้อมูล",
       icon: <BsUpload />,
    },
    {
      path: "/product/change",
      name: "หน้าอัพเดทไฟส์",
      icon: <BsFolderFill />,
    },
  ]);

  const Get_Product = () => {
    API_Product(context.id_brand[0])
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อฐานข้อมูลได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const Remove_Product = (id: string) => {
        API_Product_Remove(id)
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: "ไม่สามารถเชื่อมต่อฐานข้อมูลได้",
              timer: 5000,
              timerProgressBar: true,
            });
          });
  };

  const onClickRemove = () => {
    Swal.fire({
      icon: "warning",
      title: "คำเตือน",
      text: "คุณต้องการที่จะ ลบรายการนี้ ใช่ หรือ ไม่?",
      timer: 5000,
      timerProgressBar: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.value) {
        Selected.map(id => {
          API_Product_Remove(id);
        });
        Swal.fire({
          icon: "success",
          title: "สำเร็จ",
          text: "ลบรายการเสร็จสิ้น",
          timer: 5000,
          timerProgressBar: true,
        }).then(() => {
          window.location.reload();
        });
      }
    });
  }

  const handleChange = (selected : any) => {
    let data : Array<string> = [];
    selected.selectedRows.map((item : any) => {
      data.push(item.id_product);
    });
    setSelected(data);
  };

  useEffect(() => {
    Get_Product();
  }, []);

  return (
    <div className="is-flex">
      <Menu />
      <div className="container m-5">
        <h5 className="subtitle is-5">สินค้าทั้งหมด</h5>
        <div className="card column mb-2">
          <label className="is-block">หน้าที่เกี่ยวข้อง</label>
          {Btn.map((item, index) => (
            <button
              key={index}
              onClick={() => history.push(item.path)}
              className="button is-link mt-1 mr-2"
            >
              <b className="mr-2">{item.icon}</b>
              {item.name}
            </button>
          ))}
        </div>
        <div className="card column">
          <div className="card-content">
            <button onClick={() => onClickRemove()} disabled={Selected.length <= 0} className="button is-danger mb-2">ลบรายการ</button>
            <DataTable columns={
              [
                {
                    name: 'รหัสสินค้า',
                    selector: row => row.productconnect_id,
                    sortable: true,
                },
                {
                    name: 'ชื่อสินค้า',
                    selector: row => row.name_product,
                    sortable: true,
                },
                {
                    name: 'ราคาสินค้า',
                    selector: row => row.price_product,
                    sortable: true,
                }
              ]}
              data={Data} selectableRows onSelectedRowsChange={handleChange}  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product_Page;
