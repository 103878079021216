import React, { createContext, useState } from 'react';

export const AuthContext = createContext<any>([]);

export const AuthProvider : React.FC = ({ children }) => {

    const [Auth, setAuth] = useState(false);
    const [IdBrand, setIdBrand] = useState<number>();

    const store = {
        auth: [Auth, setAuth],
        id_brand: [IdBrand, setIdBrand]
    }

    return (
        <AuthContext.Provider value={store}>
            { children }
        </AuthContext.Provider>
    )
}
