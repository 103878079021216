import React, { useEffect, useState } from "react";
import { BsBucketFill, BsDownload, BsEye } from "react-icons/bs";
import Menu from "../Components/Menu";
import { API_Report } from "../Utils/api";

const Home = () => {
  interface ReportStruct {
    download: number
    view: number
    product: number
  };

  const [Data, setData] = useState<ReportStruct>({
    download: 0,
    view: 0,
    product: 0
  });
  

  const GetAPI = () => {
    API_Report().then(res => {
      setData(res.data);
    }).catch(() => {

    });
  }

  useEffect(() => {
    GetAPI();
  }, []);

  return (
    <div className="is-flex">
      <Menu />
      <div className="container m-5">
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-content is-flex is-justify-content-space-between">
                <div className="mr-4">
                  <h4 className="title is-4">{Data.product} ตัว</h4>
                  <h6 className="subtitle is-6 has-text-danger">
                    รายงานจำนวนสินคัาทั้งหมด
                  </h6>
                </div>
                <BsBucketFill
                  className="has-text-grey-light"
                  style={{ fontSize: "80px" }}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-content is-flex is-justify-content-space-between">
                <div className="mr-4">
                  <h4 className="title is-4">{Data.view} ครั้ง</h4>
                  <h6 className="subtitle is-6 has-text-danger">
                    รายงานผู้เข้าชมวันนี้
                  </h6>
                </div>
                <BsEye
                  className="has-text-grey-light"
                  style={{ fontSize: "80px" }}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-content is-flex is-justify-content-space-between">
                <div className="mr-4">
                  <h4 className="title is-4">{Data.download} ครั้ง</h4>
                  <h6 className="subtitle is-6 has-text-danger">
                    รายงานการดาวน์โหลดวันนี้
                  </h6>
                </div>
                <BsDownload
                  className="has-text-grey-light"
                  style={{ fontSize: "80px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
