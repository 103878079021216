import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { BsLockFill, BsPersonFill } from "react-icons/bs";
import Swal from "sweetalert2";
import { API_Login } from "../Utils/api";

const Login = () => {
  const Data = useRef({
    username: "",
    password: "",
  });

  const [Remeberme, setRemeberme] = useState<boolean>(false);

  const [Error, setError] = useState({
    username: false,
    password: false,
  });

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    event.key === "Enter" && onLogin();
  };

  const CheckEmpty = (id: string, value: string) => {
    if (value !== "") {
      setError((preState) => ({
        ...preState,
        [id]: false,
      }));
      return true;
    } else {
      setError((preState) => ({
        ...preState,
        [id]: true,
      }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  const ChangeData = (event: ChangeEvent<HTMLInputElement>) => {
    Data.current = {
      ...Data.current,
      [event.target.id]: event.target.value,
    };
  };

  const onLogin = () => {
    if (CheckEmpty("username", Data.current.username) && Check_Password()) {
      API_Login(Data.current)
        .then((res) => {
           if (Remeberme) {
            let data = JSON.stringify({
              username: Data.current.username,
              password: Data.current.password,
            });
            localStorage.setItem("remeberme", data);
          } else {
            localStorage.removeItem("remeberme");
          }
          Swal.fire({
            icon: res.status ? "success" : "error",
            title: res.status ? "สำเสร็จ" : "เกิดข้อผิดพลาด",
            text: res.message,
            timer: 5000,
            timerProgressBar: true,
          }).then(() => {
            if (res.status) {
              localStorage.setItem("token", res.token["access_token"]);
              localStorage.setItem("refresh_token", res.token["refresh_token"]);
              window.location.href = "/";
            }
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ไม่สามารถเชื่อมต่อฐานข้อมูลได้",
            timer: 5000,
            timerProgressBar: true,
          });
        });
    }
  };

  const Check_Password = (): boolean => {
    let status = false;

    if (Data.current.password !== "") {
      if (Data.current.password.length >= 10) {
        status = true;
      }
    }

    if (status) {
      setError((preState) => ({
        ...preState,
        password: false,
      }));
      return true;
    } else {
      setError((preState) => ({
        ...preState,
        password: true,
      }));
      document.getElementById("password")?.focus();
      return false;
    }
  };

   const CheckRemeberme = () => {
    if (localStorage.hasOwnProperty("remeberme")) {
      let data = JSON.parse(localStorage.getItem("remeberme") || "");
      setRemeberme(true);
      (document.getElementById("username") as HTMLInputElement).value =
        data["username"];
      (document.getElementById("password") as HTMLInputElement).value =
        data["password"];
      Data.current.username = data["username"];
      Data.current.password = data["password"];
    }
  };

  useEffect(() => {
    CheckRemeberme();
  }, []);

  return (
    <div
      className="is-flex is-justify-content-center is-align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="card" style={{ width: "400px" }}>
        <div className="card-content">
          <h4 className="title is-4 has-text-centered">เข้าสู่ระบบหลังบ้าน</h4>
          <div className="field">
            <label className="username">ชื่อผู้ใช้งาน</label>
            <div className="control has-icons-left">
              <input
                onChange={ChangeData}
                autoFocus
                onKeyPress={onEnter}
                className={`input ${Error.username ? "is-danger" : ""}`}
                id="username"
                type="text"
                placeholder="อีเมล"
              />
              <span className="icon is-small is-left">
                <BsPersonFill />
              </span>
            </div>
            {Error.username && (
              <p className="help is-danger">กรุณากรอกชื่อผู้ใช้งานให้ถูกต้อง</p>
            )}
          </div>
          <div className="field">
            <label className="password">รหัสผ่าน</label>
            <div className="control has-icons-left">
              <input
                onChange={ChangeData}
                onKeyPress={onEnter}
                className={`input ${Error.password ? "is-danger" : ""}`}
                id="password"
                type="password"
                placeholder="รหัสผ่าน"
              />
              <span className="icon is-small is-left">
                <BsLockFill />
              </span>
            </div>
            {Error.password && (
              <p className="help is-danger">กรุณากรอกรหัสผ่านให้ถูกต้อง</p>
            )}
          </div>

          <label
            htmlFor="remeberme"
            className="checkbox mb-2"
          >
            <input
              checked={Remeberme}
              onChange={() => setRemeberme(!Remeberme)}
              id="remeberme"
              type="checkbox"
            />{" "}
            จดจำ
          </label>

          <button
            onClick={() => onLogin()}
            className="button is-link is-fullwidth"
          >
            เข้าสู่ระบบ
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
