import React, { useCallback, useContext, useState } from "react";
import {
  BsBucketFill,
  BsFolderFill,
  BsCloudUpload,
  BsXCircleFill,
  BsCheckCircle,
  BsExclamationTriangleFill,
  BsArrowRepeat,
  BsPlusSquareFill,
} from "react-icons/bs";
import { useHistory } from "react-router";
import Menu from "../../Components/Menu";
import XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { API_Detail_Product_Delete, API_Detail_Product_Insert, API_Detail_Product_Update, API_Product_Update } from "../../Utils/api";
import Swal from "sweetalert2";
import { AuthContext } from "../../Utils/auth-context";

interface ItemStruct {
  No: number;
  ProductConnectID: string;
  Type: number;
  Name: string;
  CostCode: string;
  Factor: number;
  Price: number;
  Unit: string;
  Link: string;
}

interface ItemDetailStruct {
  ProductConnectID: string;
  Header: string;
  Detail: string;
}

const Product_Update_Page = () => {
  const history = useHistory();

  const context = useContext(AuthContext);

  const [Data, setData] = useState<Array<ItemStruct>>([]);
  const [DataDetail, setDataDetail] = useState<Array<ItemDetailStruct>>([]);
  const [Status, setStatus] = useState<Array<Number>>([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      DropFile(acceptedFiles);
    },
    [Data]
  );

  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  const DropFile = (acceptedFiles: Array<File>) => {
    const xlsx = acceptedFiles.find(
      (file: File) => file.name.split(".")[1] === "xlsx"
    );
    if (xlsx) {
      readExcel(xlsx).then((d: any) => {
        setData(d);
      });
    }
  };

  const readExcel = (file: File) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const wsnamedetail = wb.SheetNames[1];

        const ws = wb.Sheets[wsname];

        const wd = wb.Sheets[wsnamedetail];

        const data = XLSX.utils.sheet_to_json(ws);

        const data_detail = XLSX.utils.sheet_to_json(wd);

        let xsdata: Array<ItemStruct> = [];
        let detail: Array<ItemDetailStruct> = [];

        data.forEach((item: any) => {
          if (item.Status === "UP") {
            if (
              item.ProductConnectID !== undefined &&
              item.Factor !== undefined &&
              item.Unit !== undefined &&
              item.Type !== undefined
            ) {
              xsdata.push(item);
            } else {
              Swal.fire({
                icon: "warning",
                title: "คำเตือน",
                text: "มีบ้างช่องที่ท่านยังไม่ได้กรอก",
                timer: 1500,
                timerProgressBar: true,
              });
            }
          }
        });

        data_detail.forEach((item: any) => detail.push(item));
        setDataDetail(detail);

        resolve(xsdata);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    return promise;
  };

  const [Btn, setBtn] = useState([
    {
       path: "/product",
       name: "หน้าสินค้าทั้งหมด",
       icon: <BsBucketFill />
    },
    {
      path: "/product/add",
      name: "หน้าเพิ่มสินค้า",
      icon: <BsPlusSquareFill />,
    },
    {
      path: "/product/change",
      name: "หน้าอัพเดทไฟส์",
      icon: <BsFolderFill />,
    },
  ]);

  const Upload = async () => {
    if (Data.length > 0) {
      setStatus(Array(Data.length).fill(0));
      let status = 0;
      let index = 0;
      for (let item of Data) {
          await API_Product_Update(context.id_brand[0], item)
          .then(async (res) => {
            API_Detail_Product_Delete(res.data);
            await wait(500);
            upload_detail(item.ProductConnectID, res.data);
            await wait(1500);
              if (res.status) {
                status = 1;
              } else {
                status = 2;
              }
            })
            .catch(() => {
              status = 3;
            })
            .finally(() => {
              setStatus((preState) => ({
                ...preState,
                [index]: status,
              }));
            })
            index++;
        }
    } else {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "ไม่พบข้อมูลสินค้าในการอัพเดทข้อมูล",
        timer: 5000,
        timerProgressBar: true,
      });
    }
  };

  const wait = async (ms: number) => {
    return await new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const upload_detail = async (product_id : string, id : string) => {
    const data = DataDetail.filter(item => item.ProductConnectID === product_id);
    data.map(item => {
      API_Detail_Product_Insert(id, item);
    });
  };

  const GetStatus = (index: number) => {
    switch (Status[index]) {
      case 0:
        return (
          <BsArrowRepeat
            style={{ animation: "loading_upload 1s linear infinite" }}
            className="has-text-info"
          />
        );
      case 1:
        return <BsCheckCircle className="has-text-success" />;
      case 2:
        return <BsExclamationTriangleFill className="has-text-warning" />;
      case 3:
        return <BsXCircleFill className="has-text-danger" />;
    }
  };

  return (
    <div
      className="is-flex"
      style={{ position: "relative" }}
      {...getRootProps()}
    >
      {isDragActive && (
        <>
          <h1 className="overlay-text">
            <BsCloudUpload />
            <b>อัพโหลดไฟส์</b>
          </h1>
          <div className="overlay"></div>
        </>
      )}
      <Menu />
      <div className="container m-5">
        <h5 className="subtitle is-5">หน้าอัพเดทข้อมูลสินค้า</h5>
        <div className="card column mb-2">
          <label className="is-block">หน้าที่เกี่ยวข้อง</label>
          {Btn.map((item, index) => (
            <button
              key={index}
              onClick={() => history.push(item.path)}
              className="button is-link mt-1 mr-2"
            >
              <b className="mr-2">{item.icon}</b>
              {item.name}
            </button>
          ))}
        </div>
        <div className="card column">
          <div className="card-content">
            <div className="is-flex is-justify-content-space-between is-align-items-center mb-3">
              <div>
                {/* <SelectKind set={SetKind} /> */}
                <button onClick={() => Upload()} className="button is-link">
                  อัพเดทข้อมูลสินค้า
                </button>
              </div>
              <p>สินค้าทั้งหมด {Data.length} ตัว</p>
            </div>
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th></th>
                  <th>ลำดับ</th>
                  <th>รหัสสินค้า</th>
                  <th>ชื่อสินค้า</th>
                  <th>รหัสต้นทุน</th>
                  <th>ปัจจัย</th>
                  <th>หน่วย</th>
                  <th>ราคาสินค้า</th>
                  <th>ลิ้ง</th>
                </tr>
              </thead>
              <tbody>
                {Data.map((item, index) => (
                  <tr key={index}>
                    <th>{GetStatus(index)}</th>
                    <th>{index + 1}</th>
                    <th>{item.ProductConnectID}</th>
                    <th>{item.Name}</th>
                    <th>{item.CostCode}</th>
                    <th>{item.Factor}</th>
                    <th>{item.Unit}</th>
                    <th>{item.Price}</th>
                    <th>{item.Link}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product_Update_Page;
