import axios from "axios";

//const IP = "http://localhost:5421";
// const IP = "http://139.59.98.79";
const IP = "https://api.product-connect.com"

const API = IP + "/api/v3/";

const APIV2 = IP + "/api/v1/";

axios.interceptors.request.use(
  (request) => {
    if (request.url !== API + "auth/refresh") {
      request.headers.Authorization = `Bearer ${window.localStorage.getItem(
        "token"
      )}`;
    }
    return request;
  },
  (err) => {
    return Promise.reject(err.response);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.response.data.message === "Token is expired") {
        if (localStorage.hasOwnProperty("refresh_token")) {
          axios
            .post(
              API + "auth/refresh",
              {},
              {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "refresh_token"
                  )}`,
                },
              }
            )
            .then((res) => {
              localStorage.setItem("token", res.data.token["access_token"]);
              localStorage.setItem(
                "refresh_token",
                res.data.token["refresh_token"]
              );
            })
            .catch(() => {
              localStorage.removeItem("token");
              localStorage.removeItem("refresh_token");
              window.location.href = "/login";
              return;
            });
        }
      }
    }
    return Promise.reject(error.response);
  }
);

interface Login {
  username: String;
  password: String;
}

interface Product_Add {
  ProductConnectID: string;
  Name: string;
  Price: number;
  Link: string;
  png: File;
  skp: File;
  gltf: File;
  Type: number;
  CostCode?: string;
  Factor?: number;
  Unit?: string;
  pdf?: File;
  material?: File;
}

interface Product_Update {
  ProductConnectID: string;
  Name: string;
  Price: number;
  Type: number;
  Link: string;
}

interface DetailProduct {
  ProductConnectID: string;
  Header: string;
  Detail: string;
}

export const API_Report = () => {
  return axios
    .get(API + "report/")
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Product = (id: string) => {
  return axios
    .get(API + "product/" + id)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Product_Remove = (id: string) => {
  return axios
    .delete(API + "product/" + id)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Product_Remove_All = () => {
  return axios.delete(API+ "product/").then(res => res.data).catch(err => err.response);
};

// POST API Product
export const API_Product_Insert = async (
  id_brand: string,
  input: Product_Add
) => {
  let data = new FormData();
  data.append("id_brand", id_brand);
  data.append("id_kind", input.Type.toString());
  data.append("productconnect_id", input.ProductConnectID);
  data.append("name_product", input.Name);
  data.append("price_product", input.Price.toString());
  data.append("link", input.Link);
  data.append("image_product", input.png);
  data.append("skp_product", input.skp);
  data.append("gltf_product", input.gltf);
  input.pdf && data.append("zip_file", input.pdf);
  input.material && data.append("material_product", input.material);
  return await axios
    .post(`${API}product/`, data)
    .then((res) => res.data)
    .catch((err) => err);
};

// PAT API Product
export const API_Product_Update = async (id: string, input: Product_Update) => {
  return await axios
    .patch(`${API}product/data/`, {
      id_brand: id,
      id_kind: input.Type.toString(),
      productconnect_id: input.ProductConnectID,
      name_product: input.Name.toString(),
      price_product: input.Price,
      link: input.Link
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Product_Change_SKP = (id : string, connectid : string, file: File) => {
  const data = new FormData();
  data.append('skp_product', file);
  return axios.patch(`${API}product/skp/${connectid}`, data);
};

export const API_Product_Change_GLTF = (id : string, connectid : string, file: File) => {
  const data = new FormData();
  data.append('gltf_product', file);
  return axios.patch(`${API}product/gltf/${connectid}`, data);
};

export const API_Product_Change_PNG = (id : string, connectid : string, file: File) => {
  const data = new FormData();
  data.append('image_product', file);
  return axios.patch(`${API}product/img/${connectid}`, data);
};

export const API_Product_Change_PDF = (id : string, connectid : string, file: File) => {
  const data = new FormData();
  data.append('zip_file', file);
  return axios.patch(`${API}product/file/${connectid}`, data);
};

// POST API Detail Product Insert
export const API_Detail_Product_Insert = async (
  id: string,
  item: DetailProduct
) => {
  return axios
    .post(API + "detail/", {
      id_product: id.toString(),
      header: item.Header.toString(),
      detail: item.Detail.toString(),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

// PAT API Detail Product
export const API_Detail_Product_Update = async (id: string, input: DetailProduct) => {
  return await axios
    .patch(`${API}detail/${id}`, {
      id_product: id.toString(),
      header: input.Header.toString(),
      detail: input.Detail.toString(),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

// DELETE API Detail Product
export const API_Detail_Product_Delete = async (id: string) => {
  return await axios
    .delete(`${API}detail/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

// POST API QuickBOQ Insert
export const API_QuickBOQ_Product_Insert = async (
  id: string,
  item: Product_Add
) => {
  return await axios
    .post(API + "quickboq/", {
      id_product: id.toString(),
      subject: item.CostCode?.toString(),
      unit: item.Unit?.toString(),
      factor: item.Factor,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

// POST API LOGIN
export const API_Login = (Data: Login) => {
  return axios
    .post(API + "auth/login", {
      username: Data.username,
      password: Data.password,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

// GET API GET Data Me
export const API_Me = async () => {
  return await axios
    .post(API + "auth/me", {})
    .then((res) => res)
    .catch((err) => err.response);
};

// POST API GET Data Logout
export const API_Logout = () => {
  return axios
    .post(API + "auth/logout", {})
    .then((res) => res.data)
    .catch((err) => err.response)
    .finally();
};

// GET API GET Data Work All
export const API_GET_Work_All = () => {
  return axios
    .get(APIV2 + "work/all/1")
    .then((res) => res.data)
    .catch((err) => err.response);
};
